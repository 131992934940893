import { CERRAR_MODAL } from "redux/types";
const initialState = {
  estado: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CERRAR_MODAL:
      return {
        ...state,
        estado: true,
      };
    default:
      return state;
      break;
  }
}
