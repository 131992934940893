import { COMPROBAR_DIRECCION_FACTURACION_ERROR } from "redux/types";
import { COMPROBAR_DIRECCION_FACTURACION_EXITO } from "redux/types";
import { COMPROBAR_DIRECCION_ENTREGA_ERROR } from "redux/types";
import { COMPROBAR_DIRECCION_ENTREGA_EXITO } from "redux/types";

const initialState = {
  direccionEntregaPredeterminada: false,
  direccionFacturacionPredeterminada: false,
  codigoPostalValido: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPROBAR_DIRECCION_ENTREGA_EXITO:
      return {
        ...state,
        direccionEntregaPredeterminada: true,
      };

    case COMPROBAR_DIRECCION_ENTREGA_ERROR:
      return {
        ...state,
        direccionEntregaPredeterminada: false,
      };

    case COMPROBAR_DIRECCION_FACTURACION_EXITO:
      return {
        ...state,
        direccionFacturacionPredeterminada: true,
      };

    case COMPROBAR_DIRECCION_FACTURACION_ERROR:
      return {
        ...state,
        direccionFacturacionPredeterminada: false,
      };

    default:
      return state;
      break;
  }
}
