import React from "react";
import "assets/css/barra-inferior.css";
import { Box } from "@material-ui/core";
//icons
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  subHeader: {
    height: ({ altura }) => altura,
    minHeight: ({ altura }) => altura,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    width: "100%",
    position: ({ fixed }) => (fixed ? "fixed" : "static"),
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  subHeaderBox: {
    height: "100%",
    width: "100%",
    maxWidth: "1200px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0 20px",
  },
  subHeaderItem: {
    display: "grid",
    placeItems: "center",
    marginLeft: "20px",
    cursor: "pointer",
  },
  subHeaderIcon: {
    color: ({ color }) => color,
    "&:hover": {
      fill: "#ffffff",
    },
  },
});

const BarraInferior = ({ altura, backgroundColor, color, fixed }) => {
  const { subHeader, subHeaderBox, subHeaderItem, subHeaderIcon } = useStyles({
    altura,
    backgroundColor,
    color,
    fixed,
  });

  const goInstagram = () => {
    window.location.href =
      "https://www.instagram.com/accounts/login/?next=/cordobadecompras/";
  };

  const goFacebook = () => {
    window.location.href =
      "https://www.facebook.com/C%C3%B3rdoba-de-compras-101060389270435/";
  };

  return (
    <div className={subHeader}>
      <Box className={subHeaderBox}>
        <Box className={subHeaderItem}>
          <FacebookIcon
            className={subHeaderIcon}
            onClick={() => goFacebook()}
          />
        </Box>
        {/* <Box className={subHeaderItem}>
          <TwitterIcon className={subHeaderIcon} />
        </Box> */}
        <Box className={subHeaderItem} onClick={() => goInstagram()}>
          <InstagramIcon className={subHeaderIcon} />
        </Box>
      </Box>
    </div>
  );
};

export default BarraInferior;
