import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import LockIcon from "@material-ui/icons/Lock";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Modal from "components/Modal/Modal";
import ReplayIcon from "@material-ui/icons/Replay";
import * as API from "utils/API_V2";
import PARAMS from "utils/PARAMS";
import { validateFields } from "utils/GlobalFunctions";
import { toast } from "react-toastify";
import Register from "components/Register";
import { useDispatch } from "react-redux";
import styles from "assets/jss/material-kit-react/views/loginPage.js";

const useStyles = makeStyles(styles);

export default function Login(props) {
  const [email, setEmail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [email_forgot, setEmailForgot] = useState("");
  const [register, setRegister] = useState(false);
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [connected, setConnected] = useState(false);
  const dispatch = useDispatch();
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  useEffect(() => {
    if (connected) {
      cargarCarrito(email);
      // let numArticulos = parseInt(localStorage.getItem("numArticulos"));
    }
  }, [connected]);

  async function cargarCarrito(email) {
    let numArticulos = localStorage.getItem("numArticulos");
    // let email = localStorage.getItem("userEmail");
    let productos = JSON.parse(localStorage.getItem("productos"));
    const res = await API.cargarCarrito(email, productos, numArticulos);

    if (!res.error) {
      if (res.data.carrito) {
        localStorage.setItem("productos", JSON.stringify(res.data.carrito));
      }
      if (res.data.numArticulos) {
        localStorage.setItem("numArticulos", res.data.numArticulos);
      } else {
        localStorage.setItem("numArticulos", 0);
      }

      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }

  const login = async () => {
    var validate_fields = new Map([
      ["email", email],
      ["contraseña", pass],
    ]);
    var validate = validateFields(validate_fields);
    setError(false);
    if (validate.status) {
      const res = await API.login(email, pass, "cliente");
      if (res.error) {
        toast(res.error, { type: "warning" });
      } else {
        cargarCarrito(res.data.email);
        localStorage.setItem("apiToken", res.data.token);
        localStorage.setItem("userName", res.data.nombre);
        localStorage.setItem("userEmail", res.data.email);

        // localStorage.removeItem("productos");
        if (res.data.avatar) {
          localStorage.setItem("avatar", res.data.avatar);
        }
        setConnected(true);
      }
    } else {
      toast(validate.message, { type: "warning" });
      setError(true);
    }
  };

  const forgotPassword = async () => {
    if (!email_forgot) {
      return toast("Introduce tu direccón de correo", { type: "warning" });
    }
    const res = await API.resetPass(email_forgot);
    if (res.error) {
      return toast(res.error, { type: "warning" });
    }
    setOpen(false);
    toast("Enviado correo", { type: "success" });
  };

  function formForgot() {
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <CustomInput
          labelText="Email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: email_forgot,
            onChange: (event) => {
              const { value } = event.target;
              setEmailForgot(value);
            },
          }}
        />
      </GridItem>
    );
  }

  const classes = useStyles();

  if (register) {
    return <Register />;
  }

  return (
    <GridItem xs={12} sm={12} md={12} style={{ maxWidth: "600px" }}>
      <Card className={classes[cardAnimaton]}>
        <form className={classes.form}>
          <CardHeader color="custom" className={classes.cardHeader}>
            <h4>Login</h4>
            <div className={classes.socialLine}></div>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "email",
                defaultValue: email,
                onChange: (event) => {
                  setEmail(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && email == "" ? true : false,
              }}
            />
            <CustomInput
              labelText="Password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                defaultValue: pass,
                onChange: (event) => {
                  setPass(event.target.value);
                },
                onKeyPress: (event) => {
                  if (event.key === "Enter") {
                    login();
                  }
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && pass == "" ? true : false,
              }}
            />
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button color="custom" size="lg" onClick={() => login()}>
              Entrar
            </Button>
          </CardFooter>
          <p style={{ textAlign: "center" }}>
            Haz click aquí para{" "}
            <b
              style={{ textDecoration: "underline blue", cursor: "pointer" }}
              onClick={() => setRegister(true)}
            >
              registrarte{" "}
            </b>
          </p>
          <p style={{ textAlign: "center" }}>
            Ha olvidado su contraseña,{" "}
            <span
              style={{ color: PARAMS.customColor, cursor: "pointer" }}
              onClick={() => setOpen(true)}
            >
              pulse aquí
            </span>
          </p>
        </form>
      </Card>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        content={formForgot()}
        onConfirm={() => forgotPassword()}
        confirmIcon={<ReplayIcon />}
        confirmText="Recuperar"
        title="Introduce tu email"
      />
    </GridItem>
  );
}
