import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import { ToastContainer } from "react-toastify";
import "assets/scss/material-kit-react.scss?v=1.9.0";
import "./components/Translations/i18n";
import "react-toastify/dist/ReactToastify.css";
import store from "store";
import { Provider } from "react-redux";
import CookieConsent from "react-cookie-consent";
render(
  <Provider store={store}>
    <App />
    <ToastContainer autoClose={3000} />
    <CookieConsent
      enableDeclineButton
      declineButtonText="Rechazar"
      location="bottom"
      buttonText="Aceptar"
      cookieName="cookieMarketplaces"
      style={{ background: "#2B373B" }}
      buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
      expires={150}
    >
      Este sitio web utiliza cookies para mejorar la experiencia del usuario.{" "}
    </CookieConsent>
  </Provider>,
  document.querySelector("#root")
);
