import { VALIDAR_DIRECCION_ENTREGA_EXITO } from "redux/types";
import { VALIDAR_DIRECCION_ENTREGA_ERROR } from "redux/types";

const initialState = {
  estado: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case VALIDAR_DIRECCION_ENTREGA_EXITO:
      return {
        ...state,
        estado: true,
      };
    case VALIDAR_DIRECCION_ENTREGA_ERROR:
      return {
        ...state,
        estado: false,
      };

    default:
      return state;
      break;
  }
}
