import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "assets/img/logo/logo.png";
import BarraInferior from "components/BarraInferior/BarraInferior";
import { Link } from "react-router-dom";
import MenuBurguer from "./MenuBurguer";
import HeaderLinks from "components/Header/HeaderLinks.js";

const useStyles = makeStyles({
  header: {
    width: "100%",
    position: ({ fixed }) => (fixed ? "fixed" : "static"),
    zIndex: "3",
    backgroundColor: "#51bfc3",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
  },
  headerContainer: {
    width: "100%",
    height: "90px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 20px",
  },
  anchor: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    padding: "0 16px",
    height: "90px",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  nav: {
    height: "100%",
  },
  navContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Header({ fixed, isLogin }) {
  const {
    header,
    toolbar,
    headerContainer,
    anchor,
    img,
    nav,
    navContainer,
  } = useStyles({
    fixed,
  });

  const [isMobil, setIsMobil] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900 ? setIsMobil(true) : setIsMobil(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handleModalOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  return (
    <header className={header}>
      <BarraInferior color="#adbac0" backgroundColor="#494541" altura="35px" />
      <div className={headerContainer}>
        <div className={toolbar}>
          <Link to={`/home`} className={anchor}>
            <img src={Logo} className={img} />
          </Link>
          <div className={navContainer}>
            {isMobil ? (
              <MenuBurguer
                items={
                  <HeaderLinks
                    isLogin={isLogin}
                    isMobil={isMobil}
                    handleModalClose={handleModalClose}
                  />
                }
                handleModalOpen={handleModalOpen}
                handleModalClose={handleModalClose}
                drawerOpen={drawerOpen}
              />
            ) : (
              <nav className={nav}>
                {<HeaderLinks isLogin={isLogin} isMobil={isMobil} />}
              </nav>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
