import { STOCK_NO_DISPONIBLE } from "redux/types";
import { STOCK_RESET } from "redux/types";
import { STOCK_LOADING } from "redux/types";
import { STOCK_DISPONIBLE } from "redux/types";

const dataInicial = {
  stock: -1,
};

export default function (state = dataInicial, action) {
  switch (action.type) {
    case STOCK_LOADING:
      return {
        ...state,
        stock: -1,
      };
    case STOCK_NO_DISPONIBLE:
      return {
        ...state,
        stock: 1,
      };
    case STOCK_DISPONIBLE:
      return {
        ...state,
        stock: 2,
      };
    case STOCK_RESET:
      return {
        ...state,
        stock: 0,
      };
    default:
      return state;
      break;
  }
}
