import { ALTA_CLIENTE_TEMPORAL_LOADING } from "redux/types";
import { ALTA_CLIENTE_TEMPORAL_EXITO } from "redux/types";
import { ALTA_CLIENTE_TEMPORAL_ERROR } from "redux/types";

const initialState = {
    estado: false,
    loading: false,
    data: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ALTA_CLIENTE_TEMPORAL_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ALTA_CLIENTE_TEMPORAL_EXITO:
            return {
                ...state,
                estado: true,
                loading: false,
                data: action.payload.array,
            };
        case ALTA_CLIENTE_TEMPORAL_ERROR:
            return {
                ...state,
                estado: false,
                loading: false,
            };
        default:
            return state;
            break;
    }
}