import { CART_LOADING_ERROR } from "redux/types";
import { CART_LOADING_EXITO } from "redux/types";

export const actualizarNumArticulos = (numArticulos) => async (dispatch) => {
  try {
    dispatch({
      type: CART_LOADING_EXITO,
      payload: {
        num_articulos: numArticulos,
      },
    });
  } catch (error) {
    dispatch({
      type: CART_LOADING_ERROR,
    });
  }
};
