import axios from "axios";
import params from "utils/PARAMS";
import { OBTENER_PRODUCTOS_LOADING } from "redux/types";
import { OBTENER_PRODUCTOS_EXITO } from "redux/types";
import { OBTENER_PRODUCTOS_ERROR } from "redux/types";
import { STOCK_NO_DISPONIBLE } from "redux/types";
import { STOCK_DISPONIBLE } from "redux/types";
import { STOCK_LOADING } from "redux/types";
import { CART_LOADING } from "redux/types";
import { CART_LOADING_ERROR } from "redux/types";
import { CART_LOADING_EXITO } from "redux/types";

import * as API from "utils/API_V2";
export const cargandoCarrito = () => async (dispatch) => {
  let carrito = localStorage.getItem("productos");
  dispatch({
    type: CART_LOADING,
  });

  try {
    const res = API.cargarCarrito(carrito);

    if (!res.error) {
      dispatch({
        type: CART_LOADING_EXITO,
        payload: {
          array: res.data,
        },
      });
    } else {
      dispatch({
        type: CART_LOADING_ERROR,
      });
    }
  } catch (error) {
    console.error(error);
  }
};
