import { CARGAR_METODOS_DE_PAGO_LOADING_EXITO } from "redux/types";
import { CARGAR_METODOS_DE_PAGO_LOADING_ERROR } from "redux/types";
import { CARGAR_METODOS_DE_PAGO_LOADING } from "redux/types";

const initialState = {
    estado: false, //Comprueba si esta o no iniciado.
    loading: false, //Para el spinner de loading.
    payments: [],   //Guarda todos los metodos de pago del establecimiento
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CARGAR_METODOS_DE_PAGO_LOADING:
            return {
                ...state,
                loading: true,
            };
        case CARGAR_METODOS_DE_PAGO_LOADING_EXITO:
            return {
                ...state,
                estado: true,
                loading: false,
                payments: action.payload.array,
            };
        case CARGAR_METODOS_DE_PAGO_LOADING_ERROR:
            return {
                ...state,
            };
        default:
            return state;
            break;
    }
}