import React, { useState, useEffect } from "react";
import { logout } from "utils/API_V2";
import PARAMS from "utils/PARAMS";

import NavMobil from "./NavMobil";
import NavDesktop from "./NavDesktop";

export default function HeaderLinks({ isLogin, isMobil, handleModalClose }) {
  const [userName, setUserName] = React.useState(null);

  useEffect(() => {
    setUserName(localStorage.getItem("userName"));
  }, []);

  const handleLogout = async () => {
    const res = await logout();
    if (res.error) {
      // toast("Se ha producido un error", {type: "warning"});
    } else {
      localStorage.removeItem("apiToken");
      localStorage.removeItem("userType");
      localStorage.removeItem("userName");
      localStorage.removeItem("userEmail");
      // localStorage.removeItem("productos");
      window.location.replace(PARAMS.baseUrl);
    }
  };

  /*   const classes = useStyles(); */

  const urlActive = window.location.href.split("/").pop();

  return isMobil ? (
    <NavMobil
      urlActive={urlActive}
      handleLogout={handleLogout}
      isLogin={isLogin}
      userName={userName}
      handleModalClose={handleModalClose}
    />
  ) : (
    <NavDesktop
      urlActive={urlActive}
      handleLogout={handleLogout}
      isLogin={isLogin}
      userName={userName}
    />
  );
}
