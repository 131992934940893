import { REALIZAR_PAGO_LOADING_EXITO } from "redux/types";
import { REALIZAR_PAGO_LOADING_ERROR } from "redux/types";
import { REALIZAR_PAGO_LOADING } from "redux/types";

const initialState = {
    estado: false, //Comprueba si esta o no iniciado.
    loading: false, //Para el spinner de loading.
    respuesta: [],   //Guarda la respuesta del pago.
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REALIZAR_PAGO_LOADING:
            return {
                ...state,
                loading: true,
            };
        case REALIZAR_PAGO_LOADING_EXITO:
            return {
                ...state,
                estado: true,
                loading: false,
            };
        case REALIZAR_PAGO_LOADING_ERROR:
            return {
                ...state,
                estado: false,
            };
        default:
            return state;
            break;
    }
}