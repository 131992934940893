import React from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer } from "@material-ui/core";

const useStyles = makeStyles({
  iconBurguer: {
    height: "50px",
    width: "45px",
    fill: "#ffffff",
    cursor: "pointer",
  },
  drawer: {
    backgroundColor: "rgba(82, 191, 196, 0.1)",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function MenuBurguer({
  items,
  handleModalOpen,
  handleModalClose,
  drawerOpen,
}) {
  const { iconBurguer, drawer, container } = useStyles();

  return (
    <>
      <div id="fran-debuger" onClick={handleModalOpen} className={container}>
        <MenuIcon className={iconBurguer} />
      </div>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleModalClose}
        className={drawer}
      >
        {items}
      </Drawer>
    </>
  );
}
