import axios from "axios";
import { toast } from "react-toastify";
import { OBTENER_USER_LOADING } from "redux/types";
import { OBTENER_USER_LOADING_EXITO } from "redux/types";
import { OBTENER_USER_LOADING_ERROR } from "redux/types";
import { ES_ADMIN } from "redux/types";
import { ES_ADMIN_LOADING } from "redux/types";
import { ACTUALIZAR_AVATAR_ADMIN } from "redux/types";

import { ALTA_CLIENTE_TEMPORAL_ERROR } from "redux/types";
import { ALTA_CLIENTE_TEMPORAL_EXITO } from "redux/types";
import { ALTA_CLIENTE_TEMPORAL_LOADING } from "redux/types";
import * as API from "utils/API_V2";

export const actualizarAvatar = () => (dispatch) => {
  dispatch({
    type: ACTUALIZAR_AVATAR_ADMIN,
  });
};

export const actualizarRol = () => (dispatch) => {
  dispatch({
    type: ES_ADMIN_LOADING,
  });
  dispatch({
    type: ES_ADMIN,
  });
};

export const obtenerDatosFacturacion = () => async (dispatch) => {
  dispatch({
    type: OBTENER_USER_LOADING,
  });

  try {
    const res = await API.obtenerInfoCliente();

    if (res.data == null) {
      dispatch({
        type: OBTENER_USER_LOADING_ERROR,
      });
    } else {
      dispatch({
        type: OBTENER_USER_LOADING_EXITO,
        payload: {
          array: res.data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: OBTENER_USER_LOADING_ERROR,
    });
  }
};

const getUserInfoExito = (infoUser) => ({
  type: OBTENER_USER_LOADING_EXITO,
  payload: {
    array: infoUser.data,
  },
});

export const nuevoClienteAction = (nuevoCliente) => async (dispatch) => {
  dispatch({
    type: ALTA_CLIENTE_TEMPORAL_LOADING,
  });

  try {
    const res = await API.register(nuevoCliente);

    if (res.error) {
      dispatch({
        type: ALTA_CLIENTE_TEMPORAL_ERROR,
      });
    } else {
      localStorage.setItem("apiToken", res.data.token);
      localStorage.setItem("userName", res.data.nombre);
      localStorage.setItem("userEmail", res.data.email);
      dispatch({
        type: ALTA_CLIENTE_TEMPORAL_EXITO,
        payload: {
          array: res.data,
        },
      });

      dispatch({
        type: OBTENER_USER_LOADING_EXITO,
        payload: {
          array: res.data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: ALTA_CLIENTE_TEMPORAL_ERROR,
    });
  }
};

export const obtenerDireccionesClientes = () => async (dispatch) => {};
