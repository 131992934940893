import { CART_LOADING } from "redux/types";
import { CART_LOADING_EXITO } from "redux/types";
import { CART_LOADING_ERROR } from "redux/types";

const initialState = {
  estado: false,
  loading: false,
  num_articulos: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CART_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CART_LOADING_EXITO:
      return {
        ...state,
        loading: false,
        num_articulos: action.payload.num_articulos,
      };
    case CART_LOADING_ERROR:
      return {
        ...state,
      };

    default:
      return state;
      break;
  }
}
