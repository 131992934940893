export const OBTENER_PRODUCTOS_LOADING = "OBTENER_PRODUCTOS_LOADING";
export const OBTENER_PRODUCTOS_EXITO = "OBTENER_PRODUCTOS_EXITO";
export const OBTENER_PRODUCTOS_ERROR = "OBTENER_PRODUCTOS_ERROR";
export const CERRAR_MODAL = "CERRAR_MODAL";

export const STOCK_LOADING = "STOCK_LOADING";
export const STOCK_NO_DISPONIBLE = "STOCK_NO_DISPONIBLE";
export const STOCK_DISPONIBLE = "STOCK_DISPONIBLE";
export const STOCK_RESET = "STOCK_RESET";

export const OBTENER_ESTABLECIMIENTO_LOADING =
  "OBTENER_ESTABLECIMIENTO_LOADING";
export const OBTENER_ESTABLECIMIENTO_LOADING_EXITO =
  "OBTENER_ESTABLECIMIENTO_LOADING_EXITO";
export const OBTENER_ESTABLECIMIENTO_LOADING_ERROR =
  "OBTENER_ESTABLECIMIENTO_LOADING_ERROR";

export const OBTENER_USER_LOADING = "OBTENER_USER_LOADING";
export const OBTENER_USER_LOADING_EXITO = "OBTENER_USER_LOADING_EXITO";
export const OBTENER_USER_LOADING_ERROR = "OBTENER_USER_LOADING_ERROR";

export const ALTA_CLIENTE_TEMPORAL_LOADING = "ALTA_CLIENTE_TEMPORAL";
export const ALTA_CLIENTE_TEMPORAL_EXITO = "ALTA_CLIENTE_TEMPORAL_EXITO";
export const ALTA_CLIENTE_TEMPORAL_ERROR = "ALTA_CLIENTE_TEMPORAL_ERROR";

export const ALTA_NUEVA_DIRECCION_ENVIO_LOADING =
  "ALTA_NUEVA_DIRECCION_ENVIO_LOADING";
export const ALTA_NUEVA_DIRECCION_ENVIO_LOADING_EXITO =
  "ALTA_NUEVA_DIRECCION_ENVIO_LOADING_EXITO";
export const ALTA_NUEVA_DIRECCION_ENVIO_LOADING_ERROR =
  "ALTA_NUEVA_DIRECCION_ENVIO_LOADING_ERROR";

export const ALTA_NUEVA_DIRECCION_FACTURACION_LOADING =
  "ALTA_NUEVA_DIRECCION_FACTURACION_LOADING";
export const ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_EXITO =
  "ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_EXITO";
export const ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_ERROR =
  "ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_ERROR";

export const ELIMINAR_DIRECCION_ENVIO_LOADING =
  "ELIMINAR_DIRECCION_ENVIO_LOADING";
export const ELIMINAR_DIRECCION_ENVIO_LOADING_EXITO =
  "ELIMINAR_DIRECCION_ENVIO_LOADING_EXITO";
export const ELIMINAR_DIRECCION_ENVIO_LOADING_ERROR =
  "ELIMINAR_DIRECCION_ENVIO_LOADING_ERROR";

export const ELIMINAR_DIRECCION_FACTURACION_LOADING =
  "ELIMINAR_DIRECCION_FACTURACION_LOADING";
export const ELIMINAR_DIRECCION_FACTURACION_LOADING_EXITO =
  "ELIMINAR_DIRECCION_FACTURACION_LOADING_EXITO";
export const ELIMINAR_DIRECCION_FACTURACION_LOADING_ERROR =
  "ELIMINAR_DIRECCION_FACTURACION_LOADING_ERROR";

// export const DIRECCION_SELECCIONADA_ENVIO = "DIRECCION_SELECCIONADA_ENVIO";
// export const DIRECCION_SELECCIONADA_ENVIO_RESET = "DIRECCION_SELECCIONADA_ENVIO_RESET";
export const CAMBIAR_DIRECCION_SELECCIONADA_LOADING =
  "CAMBIAR_DIRECCION_SELECCIONADA_LOADING";
export const CAMBIAR_DIRECCION_SELECCIONADA_ERROR =
  "CAMBIAR_DIRECCION_SELECCIONADA_ERROR";
export const CAMBIAR_DIRECCION_SELECCIONADA_EXITO =
  "CAMBIAR_DIRECCION_SELECCIONADA_EXITO";

// export const DIRECCION_SELECCIONADA_FACTURACION = "DIRECCION_SELECCIONADA_FACTURACION";
// export const DIRECCION_SELECCIONADA_FACTURACION_RESET = "DIRECCION_SELECCIONADA_FACTURACION_RESET";
export const CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_LOADING =
  "CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_LOADING";
export const CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_ERROR =
  "CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_ERROR";
export const CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_EXITO =
  "CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_EXITO";

export const CARGAR_METODOS_DE_PAGO_LOADING = "CARGAR_METODOS_DE_PAGO_LOADING";
export const CARGAR_METODOS_DE_PAGO_LOADING_ERROR =
  "CARGAR_METODOS_DE_PAGO_LOADING_ERROR";
export const CARGAR_METODOS_DE_PAGO_LOADING_EXITO =
  "CARGAR_METODOS_DE_PAGO_LOADING_EXITO";

export const REALIZAR_PAGO_LOADING = "REALIZAR_PAGO_LOADING";
export const REALIZAR_PAGO_LOADING_EXITO = "REALIZAR_PAGO_LOADING_EXITO";
export const REALIZAR_PAGO_LOADING_ERROR = "REALIZAR_PAGO_LOADING_ERROR";

export const ACTUALIZAR_CARRITO_LOADING = "ACTUALIZAR_CARRITO_LOADING";
export const ACTUALIZAR_CARRITO_LOADING_EXITO =
  "ACTUALIZAR_CARRITO_LOADING_EXITO";
export const ACTUALIZAR_CARRITO_LOADING_ERROR =
  "ACTUALIZAR_CARRITO_LOADING_ERROR";

export const CART_LOADING = "CART_LOADING";
export const CART_LOADING_ERROR = "CART_LOADING_ERROR";
export const CART_LOADING_EXITO = "CART_LOADING_EXITO";

export const COMPROBAR_DIRECCION_ENTREGA_EXITO =
  "COMPROBAR_DIRECCION_ENTREGA_EXITO";
export const COMPROBAR_DIRECCION_ENTREGA_ERROR =
  "COMPROBAR_DIRECCION_ENTREGA_ERROR";

export const COMPROBAR_DIRECCION_FACTURACION_EXITO =
  "COMPROBAR_DIRECCION_FACTURACION_EXITO";
export const COMPROBAR_DIRECCION_FACTURACION_ERROR =
  "COMPROBAR_DIRECCION_FACTURACION_ERROR";

export const VALIDAR_DIRECCION_ENTREGA_EXITO =
  "VALIDAR_DIRECCION_ENTREGA_EXITO";

export const VALIDAR_DIRECCION_ENTREGA_ERROR =
  "VALIDAR_DIRECCION_ENTREGA_ERROR";

export const IMPORTE_MINIMO_ERROR = "IMPORTE_MINIMO_ERROR";
export const IMPORTE_MINIMO_EXITO = "IMPORTE_MINIMO_EXITO";

export const ZONAS_REPARTO_EXITO = "ZONAS_REPARTO_EXITO";
export const ZONAS_REPARTO_ERROR = "ZONAS_REPARTO_ERROR";

export const COMPROBAR_CUENTA_ESTABLECIMIENTO_EXITO =
  "COMPROBAR_CUENTA_ESTABLECIMIENTO_EXITO";
export const COMPROBAR_CUENTA_ESTABLECIMIENTO_ERROR =
  "COMPROBAR_CUENTA_ESTABLECIMIENTO_ERROR";

export const ACTUALIZAR_AVATAR_ADMIN = "ACTUALIZAR_AVATAR_ADMIN";
export const ES_ADMIN = "ES_ADMIN";
export const ES_ADMIN_LOADING = "ES_ADMIN_LOADING";
