import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  subHeader: {
    height: "fit-content",
    minHeight: ({ altura }) => altura,
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    width: "100%",
    position: ({ fixed }) => (fixed ? "fixed" : "static"),
    zIndex: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width: 350px)"]: {
      minHeight: "60px !important",
    },
  },
  subHeaderBox: {
    height: "100%",
    width: "100%",
    maxWidth: "1200px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 20px",
  },
});

const BarraCopyright = ({ altura, backgroundColor, color, fixed }) => {
  const { subHeader, subHeaderBox } = useStyles({
    altura,
    backgroundColor,
    color,
    fixed,
  });

  return (
    <div className={subHeader}>
      <div className={subHeaderBox}>
        &copy; Copyright {1900 + new Date().getYear()} | Comercio Córdoba
      </div>
    </div>
  );
};

export default BarraCopyright;
