import { OBTENER_ESTABLECIMIENTO_LOADING_ERROR } from "redux/types";
import { OBTENER_ESTABLECIMIENTO_LOADING_EXITO } from "redux/types";
import { OBTENER_ESTABLECIMIENTO_LOADING } from "redux/types";

const initialState = {
  establecimientos: [],
  estado: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTENER_ESTABLECIMIENTO_LOADING:
      return {
        ...state,
      };
    case OBTENER_ESTABLECIMIENTO_LOADING_EXITO:
      return {
        ...state,
        estado: true,
        establecimientos: action.payload.array,
      };
    case OBTENER_ESTABLECIMIENTO_LOADING_ERROR:
      return {
        ...state,
      };
    default:
      return state;
      break;
  }
}
