import { COMPROBAR_CUENTA_ESTABLECIMIENTO_ERROR } from "redux/types";
import { COMPROBAR_CUENTA_ESTABLECIMIENTO_EXITO } from "redux/types";

const initialState = {
  data: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMPROBAR_CUENTA_ESTABLECIMIENTO_EXITO:
      return {
        ...state,
        data: action.payload.data,
      };
    case COMPROBAR_CUENTA_ESTABLECIMIENTO_ERROR:
      return {
        ...state,
      };

    default:
      return state;
      break;
  }
}
