import React, { lazy, Suspense, useState, useEffect } from "react";
import LoginAdmin from "views/admin/LoginAdmin";
import LoginWeb from "views/web/LoginPage/LoginPage";
import "assets/css/aicor.css";
import * as API from "utils/API_V2";
import { Router, Route, Switch } from "react-router-dom";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { actualizarNumArticulos } from "redux/actions/cartAction";
import { actualizarRol } from "redux/actions/userAction";
import Mantenimiento from "layouts/Mantenimiento";

const Admin = lazy(() => import("layouts/Admin"));
const AdminEstablecimiento = lazy(() => import("layouts/AdminEstablecimiento"));
const Web = lazy(() => import("layouts/Web"));
const PerfilUser = lazy(() => import("views/web/ProfilePage/ProfilePage"));
const hist = createBrowserHistory();
const loading = () => <CustomLoading />;

export default function App(prop) {
  const dispatch = useDispatch();
  const [apiToken, setApiToken] = useState(
    localStorage.getItem("apiToken") || ""
  );
  const [user, setUser] = useState(null);
  const [login, setLogin] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    let numArticulos = parseInt(localStorage.getItem("numArticulos"));
    dispatch(actualizarNumArticulos(numArticulos));
  }, []);

  const isAdmin = async (e) => {
    let res;
    if (apiToken != "") {
      res = await API.isAdminAuth();
      if (!res.error) {
        if (res.data.rol == "admin") {
          dispatch(actualizarRol());
        }

        if (res.data.rol == "cliente") {
          // Hacemos petición para buscar el número de productos que tiene en su carrito.
        }
        // if (localStorage.getItem("productos")) {
        //   actualizarCarrito();
        // }
      } else {
      }
    } else {
      if (!localStorage.getItem("numArticulos")) {
        localStorage.setItem("numArticulos", 0);
      }
      res = await API.isAdminGuest();
    }

    if (res.error) {
      setLogin(false);
    } else {
      localStorage.setItem("avatar", res.data.imagen);
      setUser(res.data);
      setLogin(true);
    }
    setLoad(true);
  };

  useEffect(() => {
    isAdmin();
  }, []);

  if (load) {
    if (login && user.rol == "admin") {
      return (
        <Suspense fallback={loading()}>
          <Router history={hist}>
            <Switch>
              <Route
                path="/"
                component={(props) => (
                  <Admin user={user} admin={true} {...props} />
                )}
              />
            </Switch>
          </Router>
        </Suspense>
      );
    } else if (login && user.rol == "adminEstablecimiento") {
      return (
        <Suspense fallback={loading()}>
          <Router history={hist}>
            <Switch>
              <Route
                path="/"
                component={(props) => (
                  <AdminEstablecimiento user={user} admin={true} {...props} />
                )}
              />
            </Switch>
          </Router>
        </Suspense>
      );
    } else {
      return (
        <Suspense fallback={loading()}>
          <Router history={hist}>
            <Switch>
              {!login && (
                <Route
                  path="/login"
                  component={(props) => (
                    <LoginWeb
                      isLogin={login}
                      setApiToken={(token) => setApiToken(token)}
                    />
                  )}
                />
              )}
              {!login && (
                <Route
                  path="/administrador"
                  component={(props) => (
                    <LoginAdmin setApiToken={(token) => setApiToken(token)} />
                  )}
                />
              )}
              {login && (
                <Route
                  path="/perfil"
                  component={(props) => (
                    <PerfilUser
                      isLogin={login}
                      setApiToken={(token) => setApiToken(token)}
                    />
                  )}
                />
              )}
              <Route
                path="/"
                component={(props) => (
                  <Web user={user} login={login} {...props} />
                )}
              />

              {/* RUTA MANTENIMIENTO */}

              {/* <Route path="/" component={(props) => <Mantenimiento />} /> */}
            </Switch>
          </Router>
        </Suspense>
      );
    }
  } else {
    return <CustomLoading />;
  }
}
