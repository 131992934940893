import { useState, useEffect } from "react";

// import {instance as API, instance_blob as API_BLOB, instance_archivo as API_ARCHIVO} from "utils/API_V2";
import { toast } from "react-toastify";
import { cargandoCarrito } from "redux/actions/actualizarCarritoAction";
var time = [];

// Recibe array map
export function validateFields(fields) {
  var BreakException = {};
  var error = [];
  error["status"] = true;
  var field = "";

  try {
    fields.forEach(function (el, i) {
      // el === undefined. Añadido por Antonio Luis Palomeque

      if (
        (el === "" || el === null || el === [] || el === undefined) &&
        error
      ) {
        error["status"] = false;
        field = i;
        throw BreakException;
      }
    });
  } catch (e) {
    if (e !== BreakException) throw e;
  }

  if (!error.status) {
    error["message"] = "El campo " + field + " no puede estar vacio";
  }

  return error;
}

export function comprobarNumero(array) {
  let validacion = true;
  array.forEach((numero) => {
    if (numero < 0) {
      validacion = false;
    }
  });
  return validacion;
}

export function calcularCantidadProductos(idProducto) {
  let productosEnCarrito = JSON.parse(localStorage.getItem("productos"));
  let productoCantidad = 0;

  productosEnCarrito.forEach((establecimiento) => {
    establecimiento.producto.forEach((producto) => {
      if (producto.id == idProducto) {
        productoCantidad =
          parseInt(productoCantidad) + parseInt(producto.cantidad);
      }
    });
  });
  return parseInt(productoCantidad);
}

// Funcion auxiliar async
async function resolve(promise) {
  const resolved = {
    data: null,
    error: null,
  };

  try {
    resolved.data = await promise;
  } catch (e) {
    resolved.error = e;
  }

  return resolved;
}
