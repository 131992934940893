import { ALTA_NUEVA_DIRECCION_ENVIO_LOADING_EXITO } from "redux/types";
import { ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_EXITO } from "redux/types";

import { ELIMINAR_DIRECCION_ENVIO_LOADING } from "redux/types";
import { ELIMINAR_DIRECCION_ENVIO_LOADING_EXITO } from "redux/types";
import { ELIMINAR_DIRECCION_ENVIO_LOADING_ERROR } from "redux/types";

import { CAMBIAR_DIRECCION_SELECCIONADA_EXITO } from "redux/types";
import { CAMBIAR_DIRECCION_SELECCIONADA_LOADING } from "redux/types";
import { CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_EXITO } from "redux/types";

import { ELIMINAR_DIRECCION_FACTURACION_LOADING } from "redux/types";
import { ELIMINAR_DIRECCION_FACTURACION_LOADING_EXITO } from "redux/types";

import { ELIMINAR_DIRECCION_FACTURACION_LOADING_ERROR } from "redux/types";

import { CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_ERROR } from "redux/types";
import { CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_LOADING } from "redux/types";
import { CAMBIAR_DIRECCION_SELECCIONADA_ERROR } from "redux/types";



import { ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_ERROR } from "redux/types";
import { ALTA_NUEVA_DIRECCION_FACTURACION_LOADING } from "redux/types";
import { ALTA_NUEVA_DIRECCION_ENVIO_LOADING_ERROR } from "redux/types";
import { ALTA_NUEVA_DIRECCION_ENVIO_LOADING } from "redux/types";


const initialState = {
    estado: false,
    loading: false,

    estadoDireccionFacturacion: false,
    estadoDireccionEliminada: false,
    data: [],
    direccionEnvioSeleccionada: {},
    direccionFacturacionSeleccionada: {},
    direccionCambiada: false,
    direccionFacturacionCambiada: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ALTA_NUEVA_DIRECCION_ENVIO_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ALTA_NUEVA_DIRECCION_ENVIO_LOADING_EXITO:
            return {
                ...state,
                estado: true,
                loading: false,
            };
        case ALTA_NUEVA_DIRECCION_ENVIO_LOADING_ERROR:
            return {
                ...state,
                estado: false,
                loading: false,
            };
        case ALTA_NUEVA_DIRECCION_FACTURACION_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_EXITO:
            return {
                ...state,
                estadoDireccionFacturacion: true,
                loading: false,
            };
        case ALTA_NUEVA_DIRECCION_FACTURACION_LOADING_ERROR:
            return {
                ...state,
                estadoDireccionFacturacion: false,
                loading: false,
            };

        case ELIMINAR_DIRECCION_ENVIO_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ELIMINAR_DIRECCION_ENVIO_LOADING_EXITO:
            return {
                ...state,
                estadoDireccionEliminada: true,
                loading: false,
            };
        case ELIMINAR_DIRECCION_ENVIO_LOADING_ERROR:
            return {
                ...state,
                estadoDireccionEliminada: false,
                loading: false,
            };

        case ELIMINAR_DIRECCION_FACTURACION_LOADING:
            return {
                ...state,
                loading: true,
            }

        case ELIMINAR_DIRECCION_FACTURACION_LOADING_EXITO:
            return {
                ...state,
                estadoDireccionFacturacion: true,
                loading: false,
            }
        case ELIMINAR_DIRECCION_FACTURACION_LOADING_ERROR:
            return {
                ...state,
                estadoDireccionFacturacion: false,
                loading: false,
            }

        case CAMBIAR_DIRECCION_SELECCIONADA_LOADING:
            return {
                ...state,
            }
        case CAMBIAR_DIRECCION_SELECCIONADA_EXITO:
            return {
                ...state,
                direccionEnvioSeleccionada: action.payload.direccionSeleccionada,
                direccionCambiada: true,
            }
        case CAMBIAR_DIRECCION_SELECCIONADA_ERROR:
            return {
                ...state,
                direccionCambiada: false,
            }

        case CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_LOADING:
            return {
                ...state,
            }

        case CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_EXITO:
            return {
                ...state,
                direccionFacturacionSeleccionada: action.payload.direccionFacturacionSeleccionada,
                direccionFacturacionCambiada: true,
            }

        case CAMBIAR_DIRECCION_FACTURACION_SELECCIONADA_ERROR:
            return {
                ...state,
                direccionFacturacionCambiada: false,
            }

        default:
            return state;
            break;
    }
}