import { OBTENER_PRODUCTOS_ERROR } from "redux/types";
import { OBTENER_PRODUCTOS_EXITO } from "redux/types";
import { OBTENER_PRODUCTOS_LOADING } from "redux/types";

const initialState = {
  estado: false,
  loading: false,
  productos: [],
  msg: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OBTENER_PRODUCTOS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case OBTENER_PRODUCTOS_EXITO:
      return {
        ...state,
        estado: true,
        loading: false,
        productos: action.payload.array,
        msg: action.payload.array.msg,
      };
    case OBTENER_PRODUCTOS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
      break;
  }
}
