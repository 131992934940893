import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import People from "@material-ui/icons/People";
import LockIcon from "@material-ui/icons/Lock";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as API from "utils/API_V2";
import { toast } from "react-toastify";
import Login from "components/Login";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import { checkObject } from "utils/VALIDATION";
import { Link } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function Register(props) {
  const [nombre, setNombre] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [error, setError] = useState("");
  const [userType, setUserType] = useState("cliente");
  const [terminos, setTerminos] = useState(false);
  const [login, setLogin] = useState(false);

  const nuevoUsuario = {
    nombre: nombre,
    apellidos: apellidos,
    email: email,
    pass: pass,
    userType: userType,
  };

  const validate_fields_cliente = {
    nombre: { type: "NULL", field: "Nombre" },
    apellidos: { type: "NULL", field: "Apellidos" },
    email: { type: "EMAIL", field: "Email" },
    pass: { type: "NULL", field: "Contraseña" },
  };

  useEffect(() => {}, []);

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);

  const register = async () => {
    if (pass !== pass2) {
      return toast("Las contraseñas no coinciden", { type: "warning" });
    }

    var validation = checkObject(validate_fields_cliente, nuevoUsuario);

    if (!validation.status) {
      return toast(validation.message, { type: "warning" });
    }

    const res = await API.register(nuevoUsuario, "cliente");

    if (res.error) {
      return toast(res.error.email[0], { type: "warning" });
    }

    localStorage.setItem("apiToken", res.data.token);
    localStorage.setItem("userName", res.data.nombre);
    localStorage.setItem("userEmail", res.data.email);
    window.location.reload(false);
  };

  const classes = useStyles();

  if (login) {
    return <Login md={props.md} />;
  }

  return (
    <GridItem xs={12} sm={12} md={12} style={{ maxWidth: "600px" }}>
      <Card className={classes[cardAnimaton]}>
        <form className={classes.form}>
          <CardHeader color="custom" className={classes.cardHeader}>
            <h4>Registrarse</h4>
          </CardHeader>
          <CardBody>
            <CustomInput
              labelText="Nombre"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                defaultValue: nombre,
                onChange: (event) => {
                  setNombre(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && nombre == "" ? true : false,
              }}
            />
            <CustomInput
              labelText="Apellidos"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                defaultValue: apellidos,
                onChange: (event) => {
                  setApellidos(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && apellidos == "" ? true : false,
              }}
            />
            <CustomInput
              labelText="Email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "text",
                defaultValue: email,
                onChange: (event) => {
                  setEmail(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <People className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && email == "" ? true : false,
              }}
            />
            <CustomInput
              labelText="Contraseña"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                defaultValue: pass,
                onChange: (event) => {
                  setPass(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && pass == "" ? true : false,
              }}
            />
            <CustomInput
              labelText="Repetir contraseña"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                defaultValue: pass2,
                onChange: (event) => {
                  setPass2(event.target.value);
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <LockIcon className={classes.inputIconsColor} />
                  </InputAdornment>
                ),
                error: error && pass2 == "" ? true : false,
              }}
            />

            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={terminos}
                    onChange={() => setTerminos(!terminos)}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Aceptar terminos y condiciones"
              />
              <Link to="/avisolegal" className={classes.link}>
                [Leer más]
              </Link>
            </div>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            {terminos && (
              <Button color="custom" size="lg" onClick={() => register()}>
                Registrarse
              </Button>
            )}
          </CardFooter>
        </form>
      </Card>
    </GridItem>
  );
}
