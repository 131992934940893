import { combineReducers } from "redux";
import checkoutReducer from "./checkoutReducer";
import controlStock from "./controlStockReducer";
import establecimientos from "./establecimientosReducer";
import utilidades from "./utilsReducer";
import facturacionUsuario from "./userReducer";
import clienteTemporal from "./clienteTemporalReducer";
import direcciones from "./direccionesReducer";
import metodosDePago from "./metodosDePagoReducer";
import realizarPago from "./realizarPagoReducer";
import cart from "./cartReducer";
import infoDirecciones from "./comprobarDireccionesReducer";
import validarDireccionEntrega from "./validarDireccionEntregaReducer";
import comprobarCuenta from "./comprobarCuentaReducer";

export default combineReducers({
  checkout: checkoutReducer,
  utilidades: utilidades,
  establecimientos: establecimientos,
  controlStock: controlStock,
  facturacionUsuario: facturacionUsuario,
  clienteTemporal: clienteTemporal,
  direcciones: direcciones,
  metodosDePago: metodosDePago,
  realizarPago: realizarPago,
  cart: cart,
  infoDirecciones: infoDirecciones,
  validarDireccionEntrega: validarDireccionEntrega,
  comprobarCuenta: comprobarCuenta,
});
