import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Login from "components/Login";
import Register from "components/Register";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/bg2.jpg";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  const classes = useStyles();
  const { isLogin } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="MarketPlaces"
        rightLinks={<HeaderLinks isLogin={isLogin} />}
        {...props}
        fixed={true}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          paddingTop: "120px",
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            {!isLogin ? <Login /> : <Register />}
          </GridContainer>
        </div>
      </div>
      <Footer />
    </div>
  );
}
